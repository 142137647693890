#root {
  height: 100%;
}

#App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
}

#AppContent {
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative;
}

.Pane1 {
  display: -webkit-flex;
  display: flex;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
cursor: not-allowed;
}

.Resizer.disabled:hover {
border-color: transparent;
}
#topBarRoot {
  border-radius: 0px;
  width: 100%;
  margin-bottom: 0px;
  min-height: 74px !important;
  max-height: 74px !important;
}

.fileSummary {
  margin-right: 10px;
}

.header.item.top-bar-header {
  padding-top: 7px !important;
  padding-left: 0px !important;
}


.message {
  cursor: pointer;
}

.confirmation-content {
  padding: 15px;
}
.react-grid-Container, 
.react-grid-Main,
.react-grid-Canvas,
.react-grid-Grid {
  height: 100% !important;
}

.row-viewer-panel {
  width: 100%
}

.row-viewer-header {
  padding: 10px;
  border-top: 1px solid grey;
  background-color: white;
}

.row-viewer-header-content {
  margin-right: 10px;
  font-size: 15px;
}

.react-grid-Cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.filter-list {
  display: inline-block;
}

.filter-item {
  display: inline-block;
  margin-right: 5px;
}

.filter-item-newline {
  margin: 0px;
  margin-top: 5px;
}
.vertical.menu {
  margin-bottom: 0px;
}

.action-panel-content {
  padding: 15px;
  width: 100%;
}
