.react-grid-Container, 
.react-grid-Main,
.react-grid-Canvas,
.react-grid-Grid {
  height: 100% !important;
}

.row-viewer-panel {
  width: 100%
}

.row-viewer-header {
  padding: 10px;
  border-top: 1px solid grey;
  background-color: white;
}

.row-viewer-header-content {
  margin-right: 10px;
  font-size: 15px;
}

.react-grid-Cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
