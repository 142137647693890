#topBarRoot {
  border-radius: 0px;
  width: 100%;
  margin-bottom: 0px;
  min-height: 74px !important;
  max-height: 74px !important;
}

.fileSummary {
  margin-right: 10px;
}

.header.item.top-bar-header {
  padding-top: 7px !important;
  padding-left: 0px !important;
}

